
import { defineComponent } from 'vue'
import { shake } from '@/utils/util'
import { getEnterpriseList } from '@/api/home'
import { getDispatchList } from '@/api/long-insure'
export default defineComponent({
    props: ['dataTitle', 'list', 'width', 'isCloseType', 'headList', 'echo'],
    data() {
        return {
            switchCard: false,
            enterpriseName: '',
            enterpriseList: [],
            enterpriseObj: {} as any,
            headName: '',
            phone: '',
            dataHeadList: [] as any,
            isHeadWhole: 2,
            isAdvance: false,
            isPhone: false
        }
    },
    watch: { // 监听
        headList(curVal, oldVal) {
            this.dataHeadList = curVal
            this.phone = curVal[0].phone
            console.log(this.dataHeadList, 'dataHeadList')
            console.log(curVal, oldVal, '监听触发')
        }
    },
    created() {
        console.log(this.echo, 'echo')
        if (this.echo) {
            this.phone = this.echo.phone
            this.enterpriseName = this.echo.serveCompanyName
            this.switchCard = !!this.echo.isNeedIdcardPhoto
            this.dataHeadList = [{
                userName: this.echo.contacts,
                id: this.echo.createUserId
            }]
            this.enterpriseObj.id = this.echo.id
            this.isAdvance = !!this.echo.isOpenAdvance
            this.isPhone = !!this.echo.isShowContacts
        }
    },
    methods: {
        onEnterpriseName() {
            if (!this.enterpriseName) {
                this.enterpriseList = []
                return
            }
            shake((): any => {
                getEnterpriseList({
                    name: this.enterpriseName
                }).then(res => {
                    console.log(res, 'res')
                    this.enterpriseList = res
                })
            })
        },
        // 单个选择
        onSelect(event: any) {
            console.log(event, 'event')
            this.enterpriseList = []
            this.enterpriseObj = event
            this.enterpriseName = event.name
        },
        onSelectHead() {
            this.$emit('selectHead')
        },
        onDeleteHead(id: string) {
            this.dataHeadList = this.dataHeadList.filter((item: any) => item.id !== id)
            console.log(id, 'id')
        },
        onHeadWhole() {
            this.isHeadWhole = this.dataHeadList.length
            console.log('显示全部')
        },
        // 确定添加
        onAddDetermine() {
            if (!this.phone) {
                this.$message.error('请输入手机号')
                return
            }
            if (!this.enterpriseObj.id) {
                this.$message.error('请选择公司')
                return
            }
            if (this.dataHeadList.length === 0) {
                this.$message.error('请至少选择一个负责人')
                return
            }
            this.$emit('determine', this.dataHeadList, this.enterpriseObj, this.phone, this.switchCard, this.isAdvance, this.isPhone)
        },
        close() {
            this.$emit('close')
            this.$Bus.emit('close2', '关闭了吗')
        }
    }
})
