
import { defineComponent } from 'vue'
import { getTeamList } from '@/api/home'
import { shake } from '@/utils/util'
export default defineComponent({
    // selectType == 'one' 是单选
    props: ['dataTitle', 'list', 'width', 'isCloseType', 'selectType'],
    data () {
        return {
            tabsIndex: '0',
            searchInput: '',
            teamList: new Set([]) as any,
            selectedList: new Set([]) as any,
            selectedLength: 0, // 这个是团队成员选择人数
            ordinarySelectedList: [] as any,
            arr: new Set([]) as any // 这里存储的东西是模糊匹配的item
        }
    },
    async created () {
        const result = await getTeamList()
        result.list = result.map((item:any) => {
            return {
                ...item,
                isSelect: false
            }
        })
        this.teamList = result.list
        console.log(this.teamList, 'teamList')
    },
    mounted () {
        console.log('组件卸载')
    },
    methods: {
        // 搜索人因为前段可以拿到使用数据所以 前端来做模糊搜索 更方便使用 new Set 语法
        onSearchInput () {
            shake(():any => {
                console.log(this.searchInput, '搜索')
                this.arr.clear()
                this.teamList.forEach((item:any) => {
                    if (item.userName.includes(this.searchInput)) {
                        this.arr.add(item)
                    }
                })
            })
        },
        onSelected (event:any) {
            // 单选
            if (this.selectType === 'one') {
                if (this.selectedList.has(event)) {
                    this.teamList.forEach((item:any, index:number) => {
                        if (item.userId === event.userId) {
                            this.teamList[index].isSelect = false
                        }
                    })
                    this.selectedList.delete(event)
                    this.ordinarySelectedList = [...this.selectedList]
                    this.selectedLength = this.selectedList.size
                    return
                }
                this.selectedList.clear()
                this.selectedList.add(event)
                this.teamList.forEach((item:any, index:number) => {
                    if (item.userId !== event.userId) {
                        this.teamList[index].isSelect = false
                    }
                })
                this.ordinarySelectedList = [...this.selectedList]
                this.selectedLength = this.selectedList.size
            } else {
                // 多选
                if (this.selectedList.has(event)) {
                    this.teamList.forEach((item:any, index:number) => {
                        if (item.id === event.id) {
                            console.log(item, 'item')
                            this.teamList[index].isSelect = false
                        }
                    })
                    this.selectedList.delete(event)
                    this.ordinarySelectedList = [...this.selectedList]
                    this.selectedLength = this.selectedList.size
                    return
                }
                this.selectedList.add(event)
                this.ordinarySelectedList = [...this.selectedList]
                this.selectedLength = this.selectedList.size
            }
        },
        onDetermine () {
            this.$emit('determine', this.ordinarySelectedList)
        },
        close () {
            this.$emit('close')
            this.$Bus.emit('close2', '关闭了吗')
        }
    }
})
