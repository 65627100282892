import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e69a264"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container block" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_popupTeamMember = _resolveComponent("popupTeamMember")!
  const _component_popupEnterprise = _resolveComponent("popupEnterprise")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "请输入人员/派遣单位/项目名称",
          class: "input search-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.searchText]
        ]),
        _createElementVNode("button", {
          class: "btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
        }, "搜索"),
        _createElementVNode("button", {
          class: "btn-white",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
        }, "重置")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_table, {
        data: _ctx.tableData,
        style: {"width":"100%"},
        "header-row-style": { color: '#202536' },
        "row-style": { color: '#5E617D' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            width: "300",
            prop: "serveCompanyName",
            label: "派遣单位名"
          }),
          _createVNode(_component_el_table_column, {
            prop: "ppCount",
            label: "项目数"
          }),
          _createVNode(_component_el_table_column, {
            prop: "progressCount",
            label: "进行中的项目"
          }),
          _createVNode(_component_el_table_column, {
            prop: "contacts",
            label: "负责人"
          }),
          _createVNode(_component_el_table_column, { label: "操作" }, {
            default: _withCtx((scope) => [
              _createElementVNode("p", {
                class: "link",
                onClick: ($event: any) => (_ctx.goEnterprise(scope.row.id))
              }, " 查看详情 ", 8, _hoisted_5)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"]),
      _createVNode(_component_el_pagination, {
        class: "pagination",
        currentPage: _ctx.pageNum,
        "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageNum) = $event)),
        "page-size": _ctx.pageSize,
        "onUpdate:page-size": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSize) = $event)),
        "page-sizes": [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: _ctx.total,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.getList
      }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
    ]),
    (_ctx.isPopupTeamMember)
      ? (_openBlock(), _createBlock(_component_popupTeamMember, {
          key: 0,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isPopupTeamMember = false)),
          selectType: "one",
          onDetermine: _ctx.onAddHead,
          dataTitle: "添加成员"
        }, null, 8, ["onDetermine"]))
      : _createCommentVNode("", true),
    (_ctx.isEnterprise)
      ? (_openBlock(), _createBlock(_component_popupEnterprise, {
          key: 1,
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isEnterprise = false)),
          onDetermine: _ctx.onAddEnterprise,
          onSelectHead: _ctx.onSelectHead,
          headList: _ctx.headList,
          dataTitle: "添加企业"
        }, null, 8, ["onDetermine", "onSelectHead", "headList"]))
      : _createCommentVNode("", true)
  ]))
}