
import { defineComponent } from 'vue'
import { getEnterpriseList, EnterpriseDto } from '@/api/enterprise'
import { addBindCompanySupplier } from '@/api/home'
import popupTeamMember from '@/components/popup-team-member.vue'
import popupEnterprise from '@/components/popup-enterprise.vue'
export default defineComponent({
    name: 'Enterprise',
    components: {
        popupTeamMember,
        popupEnterprise
    },
    data () {
        return {
            isEnterprise: false,
            isPopupTeamMember: false,
            tableData: [] as EnterpriseDto[],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            searchText: '',
            searchValue: '',
            headList: []
        }
    },
    created () {
        this.getList()
    },
    methods: {
        async getList () {
            const result = await getEnterpriseList({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                searchText: this.searchValue
            })
            this.total = result.total
            this.tableData = result.list
        },
        // 选择负责人回调
        onAddHead (event:any) {
            console.log(event, 'event')
            this.headList = event
            console.log(this.headList, 'headList')
            this.isPopupTeamMember = false
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getList()
        },
        goEnterprise (id: number) {
            this.$router.push({
                path: '/enterprise/detail/' + id
            })
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        // 打开选择负责人弹窗
        onSelectHead () {
            this.isPopupTeamMember = true
        },
        // 确认添加企业回调
        async onAddEnterprise (head:any, enterprise:any, phone:string, switchCard:boolean, isAdvance: any, isPhone: any) {
            console.log(head, enterprise, phone, switchCard)
            addBindCompanySupplier({
                area: enterprise.area || '暂无', // 公司位置
                contacts: head[0].userName, // 选择的name
                contactsId: head[0].id, // 选择的id
                createUserId: 2, // user.userId
                creditCode: enterprise.creditCode,
                isNeedIdcardPhoto: switchCard ? 1 : 0, // 是否需要照片
                phone: phone, // 电话
                serveCompanyName: enterprise.name, // 公司名称
                isOpenAdvance: isAdvance * 1,
                isShowContacts: isPhone * 1
            }).then(() => {
                this.isEnterprise = false
                this.$message.success('创建成功')
            }).catch(() => {
                this.isEnterprise = true
            })
        }
    }
})
