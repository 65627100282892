
import { defineComponent } from 'vue'
import { getEnterpriseList, EnterpriseDto, getAllEnterpriseList, deleteCompany, editCompany, serveCompanyAdvanceCount } from '@/api/enterprise'
import { addBindCompanySupplier } from '@/api/home'
import popupTeamMember from '@/components/popup-team-member.vue'
import popupEnterprise from '@/components/popup-enterprise.vue'
import { confirm } from '@/utils/util'
export default defineComponent({
    name: 'EnterpriseList',
    components: {
        popupTeamMember,
        popupEnterprise
    },
    data() {
        return {
            isEnterprise: false,
            isPopupTeamMember: false,
            tableData: [] as EnterpriseDto[],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            searchText: '',
            searchValue: '',
            headList: [],
            isEditEnterprise: false, // 编辑企业弹窗
            currentItem: {}
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async getList() {
            const result = await getAllEnterpriseList({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                serveCompanyName: this.searchText
            })
            console.log(result, 'result')
            this.total = result.totalCount
            this.tableData = result.list
        },
        // 选择负责人回调
        onAddHead(event: any) {
            console.log(event, 'event')
            this.headList = event
            console.log(this.headList, 'headList')
            this.isPopupTeamMember = false
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getList()
        },
        goEnterprise(id: number) {
            this.$router.push({
                path: '/enterprise/detail/' + id
            })
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getList()
        },
        onReset() {
            this.searchText = ''
            this.search()
        },
        // 打开选择负责人弹窗
        onSelectHead() {
            this.isPopupTeamMember = true
        },
        // 打开编辑企业弹窗
        onIsEditEnterprise(item: any) {
            this.currentItem = item
            this.isEditEnterprise = true
        },
        // 确认添加企业回调
        async onAddEnterprise(head: any, enterprise: any, phone: string, switchCard: boolean, isAdvance: any, isPhone: any) {
            console.log(head, enterprise, phone, switchCard)
            // const query = {
            //     area: enterprise.area || '暂无', // 公司位置
            //     contacts: head[0].userName, // 选择的name
            //     contactsId: head[0].userId, // 选择的id
            //     createUserId: 2, // user.userId
            //     creditCode: enterprise.creditCode,
            //     isNeedIdcardPhoto: switchCard ? 1 : 0, // 是否需要照片
            //     phone: phone, // 电话
            //     serveCompanyName: enterprise.name, // 公司名称
            //     isOpenAdvance: isAdvance * 1,
            //     isShowContacts: isPhone * 1
            // }
            addBindCompanySupplier({
                area: enterprise.area || '暂无', // 公司位置
                contacts: head[0].userName, // 选择的name
                contactsId: head[0].userId, // 选择的id
                createUserId: 2, // user.userId
                creditCode: enterprise.creditCode,
                isNeedIdcardPhoto: switchCard ? 1 : 0, // 是否需要照片
                phone: phone, // 电话
                serveCompanyName: enterprise.name, // 公司名称
                isOpenAdvance: isAdvance * 1,
                isShowContacts: isPhone * 1
            }).then(() => {
                this.isEnterprise = false
                this.$message.success('创建成功')
                this.handleSizeChange()
            }).catch(() => {
                this.isEnterprise = true
            })
        },
        // 确认修改回调
        async onEditEnterprise(head: any, enterprise: any, phone: string, switchCard: boolean, isAdvance: any, isPhone: any) {
            console.log(head, enterprise, phone, switchCard, '编辑回调')
            // const query = {
            //     contacts: head[0].userName,
            //     contactsId: head[0].id,
            //     id: enterprise.id,
            //     isNeedIdcardPhoto: switchCard ? 1 : 0,
            //     phone: phone,
            //     isOpenAdvance: isAdvance * 1,
            //     isShowContacts: isPhone * 1
            // }
            // console.log(1111, query);
            // 编辑企业
            editCompany({
                contacts: head[0].userName,
                contactsId: head[0].id ||head[0].userId,
                id: enterprise.id,
                isNeedIdcardPhoto: switchCard ? 1 : 0,
                phone: phone,
                isOpenAdvance: isAdvance * 1,
                isShowContacts: isPhone * 1
            }).then(result => {
                this.isEditEnterprise = false
                console.log(result, 'result')
            }).finally(() => {
                this.search()
            })
        },
        // 删除企业
        async onDeleteCompany(id: string) {
            const res = await serveCompanyAdvanceCount({
                serveCompanyId: id
            }) as any
            let message = '是否确定删除该企业？'
            if (res > 0) {
                message = `监测到该企业下有${res}条预支申请还未处理，删除后系统默认驳回该预支申请单。`
            }
            confirm('提示', message).then(async () => {
                await deleteCompany({
                    id: id
                })
                this.onReset()
            })
        },
        handleSwitchChange(row: any) {
            const headers = [
                {
                    userName: row.contacts,
                    id: row.contactsId
                }
            ]
            const enterprise = {
                id: row.id
            }
            this.onEditEnterprise(headers, enterprise, row.phone, row.isNeedIdcardPhoto, row.isOpenAdvance, row.isShowContacts)
        }
    }
})
